import React, {Component} from 'react';
import {connect} from 'react-redux';
import {cn, PropTypes} from '../../util';
import { CarouselComponent } from '../skeleton/controls/Carousel';


export class CarouselHomeComponent extends Component {
  static propTypes = {
    app: PropTypes.any.isRequired,
    lang: PropTypes.lang
  }

  render() {
    const {lang, app} = this.props;

    const items = [
      { src: 'vacation_202409_' + lang, format: 'png?V001', until: new Date(2024, 9, 3), altText: '', caption: '', trans: true, blob: false},
      { src: 'vacation_202410_' + lang, format: 'png?V001', until: new Date(2024, 9, 30), altText: '', caption: '', trans: true, blob: false},
      { src: 'banner_new_hours_' + lang, format: 'png?V001', altText: '', caption: '', trans: true, blob: false},
      { src: 'hit-em-hard', format: 'jpg', altText: '', caption: '', trans: false, blob:true },
      { src: 'firefighter', format: 'jpg', altText: '', caption: '', trans: false, blob:true },
      { src: 'mountainz', format: 'jpg', altText: '', caption: '', trans: false, blob:true },
      { src: 'jet', format: 'jpg', altText: '', caption: '', trans: false, blob:true },
      { src: '3W0A7593_1920x800_resized', format: 'jpg', altText: '', caption: '', trans: false, blob:true },
      { src: '511-Rush-backpack-action-1_1920x800_resized', format: 'jpg', altText: '', caption: '', trans: false, blob:true},
      { src: 'maxresdefault_1920x800', format: 'jpg', altText: '', caption: '', trans: false, blob:true },
    ].map(img => ({...img, src: img.blob ? process.env.REACT_APP_CAROUSEL_IMAGES + '/' + img.src : 'images/CarouselImages/' + img.src }));

    return (
      <section className="carousel">
        <div className="row">
          <div className={cn(app.deviceSameOrLargerThan('md') ? 'col-12' : '')}>
            <CarouselComponent carouselItems={items} />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({app: state.app, lang: state.t.lang});
export const CarouselHome = connect(mapStateToProps)(CarouselHomeComponent);
